import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

export default function GallerieSectionPage() {
  const [images, setImagesData] = useState([]);

  useEffect(() => {
    fetch('/data.json')
      .then((response) => response.json())
      .then((data) => setImagesData(data.galleryPhoto))
      .catch((error) => console.error('Erreur lors de la récupération des données :', error));
  }, []);

  return (
    <section className="py-12 bg-gradient-to-b from-black via-[#0A0F2C] to-black">
      <div className="container mx-auto">
        <div className="grid grid-cols-3 grid-rows-4 gap-8 md:grid-cols-4">
          {images.map((image, index) => (
            <motion.div
              key={index}
              className={`${image.style} relative overflow-hidden group`}
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true, amount: 0.2 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
            >
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-[10rem] object-cover rounded-lg md:h-[50rem] group-hover:scale-105 transition-transform duration-300 ease-in-out"
              />
              <div className="absolute inset-0 border-2 flex flex-col p-8 m-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
