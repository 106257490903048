import React, { useEffect, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import CalendlyIframe from "../iframe/Calendly";
import platineImg from '../../images/platine.webp'; 
import profileNoe from '../../images/profile_noe.png';
import { Link } from "react-router-dom";

export default function FonctionnementSection() {
  const { scrollYProgress } = useScroll(); 
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 360]); 
  const [stepsSection, setstepsSectionData] = useState([]); 
    
  useEffect(() => {
      fetch('/data.json')
          .then(response => response.json())
          .then(data => setstepsSectionData(data.stepsSection)) 
          .catch(error => console.error('Erreur lors de la récupération des données :', error));
  }, []);


  return (
    <section className="bg-gradient-to-t from-[#0A0F2C] to-black text-white py-12 px-6 pb-36">
    <h1 className="text-4xl md:text-5xl text-center font-custom">Notre fonctionnement</h1>
    <p className="text-center mt-4 md:mt-8 text-base md:text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.</p>
  
    <div className="container mx-auto mt-12 md:mt-24 flex flex-col md:flex-row items-start justify-between">
      
      <div className="w-full md:w-1/2 flex justify-center items-center mb-12 md:mb-0">
        <motion.div
          className="w-[19rem] md:w-[54rem] h-[19rem] md:h-[54rem] rounded-full overflow-hidden"
          style={{ rotate }} 
        >
          <img
            src={platineImg} 
            alt="Disque"
            className="w-full h-full object-cover"
          />
        </motion.div>
      </div>
  
      <div className="w-full md:w-1/2 flex flex-col space-y-12 md:space-y-16">
        {stepsSection.map((step, index) => (
          <div
            key={step.id}
            className={`flex flex-col md:flex-row items-center space-x-0 md:space-x-4 ${index % 2 === 1 ? 'md:flex-row-reverse' : ''}`} // Inversion sur un élément sur deux
          >
            <img
              src={step.img}
              alt="Image"
              className="w-24 h-24 md:w-36 md:h-36 rounded-full object-cover mb-4 md:mb-0"
            />
  
            <div className="text-center md:text-left">
              <h2 className="text-xl md:text-2xl font-bold">{step.title}</h2>
              <p className="mt-2 w-64 md:w-80 font-rubik text-[#ffffff9e]">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className=" text-white flex flex-col items-center pt-16 mt-8">
                <h2 className="text-2xl font-bold mb-4">Prenez Rendez-vous</h2>
                <p className="mb-8 text-center px-8">
                    Cliquez ci-dessous pour planifier un rendez-vous avec Noe via Calendly, ou utilisez le <Link className="underline" to="/contact">formulaire de contact</Link> pour toute autre demande.
                </p>
          
                <CalendlyIframe btnText="Appel de 30min gratuit" color="#FFD700" colorHover="#FEA160" text="#000" />

                {/* Alternative: Embed Calendly directly with iframe */}
    
            </div>

  </section>  
  );
}


