import React from "react";

export default function CGU() {
    return (
        <div className="bg-black pt-36 text-white py-16 px-8">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-bold mb-8 text-yellow-500">Conditions Générales d'Utilisation (CGU)</h1>
                <p className="mb-6">Mis à jour le 05/11/2024</p>
                {/* 1. Objet */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">1. Objet</h2>
                    <p>
                        Les présentes conditions générales d'utilisation (CGU) ont pour objet de définir les modalités
                        d'accès et d'utilisation du site <strong>HYDRO-EVENTS</strong>, accessible à l'adresse <strong>www.hydroevents.fr</strong>.
                        En accédant et en utilisant ce site, l'utilisateur accepte sans réserve les présentes CGU.
                    </p>
                </section>

                {/* 2. Accès au site */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">2. Accès au site</h2>
                    <p>
                        Le site est accessible gratuitement à tout utilisateur disposant d'un accès à Internet. 
                        <strong>HYDRO-EVENTS</strong> s'efforce de maintenir un accès au site 24 heures sur 24,
                        7 jours sur 7, mais se réserve le droit, sans préavis ni indemnité, de fermer temporairement ou définitivement
                        le site ou l'accès à certains services pour des raisons techniques ou de maintenance.
                    </p>
                </section>

                {/* 3. Propriété intellectuelle */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">3. Propriété intellectuelle</h2>
                    <p>
                        Tous les éléments présents sur ce site, y compris les textes, images, vidéos, graphismes, logos, icônes,
                        et autres éléments, sont protégés par les lois relatives à la propriété intellectuelle.
                        Toute utilisation, reproduction, diffusion, commercialisation, modification de tout ou partie de ces
                        éléments sans l'autorisation préalable et écrite de <strong>HYDRO-EVENTS</strong> est interdite
                        et peut entraîner des actions en justice.
                    </p>
                </section>

                {/* 4. Données personnelles */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">4. Données personnelles</h2>
                    <p>
                        <strong>[Nom du client ou de l'entreprise]</strong> respecte les dispositions du Règlement Général sur la Protection des Données (RGPD).
                        Les données personnelles collectées via le site sont traitées conformément à la politique de confidentialité
                        accessible sur ce site. L'utilisateur dispose d'un droit d'accès, de rectification et de suppression des données
                        le concernant, qu'il peut exercer en contactant <strong>[email@example.com]</strong>.
                    </p>
                </section>

                {/* 5. Cookies */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">5. Cookies</h2>
                    <p>
                        Ce site utilise des cookies pour améliorer l'expérience utilisateur et pour réaliser des statistiques d'audience.
                        L'utilisateur a la possibilité de désactiver les cookies en configurant son navigateur, mais cela pourrait
                        affecter certaines fonctionnalités du site.
                    </p>
                </section>

                {/* 6. Responsabilité */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">6. Responsabilité</h2>
                    <p>
                        <strong>HYDRO-EVENTS</strong> s'efforce de fournir des informations exactes et à jour sur ce site.
                        Toutefois, il ne peut garantir que les informations diffusées soient complètes, exhaustives ou actualisées en permanence.
                        L'utilisateur reconnaît utiliser les informations présentes sur le site sous sa propre responsabilité.
                    </p>
                    <p className="mt-4">
                        <strong>HYDRO-EVENTS</strong> ne pourra être tenu responsable des dommages matériels ou immatériels
                        directs ou indirects résultant de l'accès ou de l'utilisation du site, y compris en cas de bug, d'inexactitude
                        des informations ou de virus.
                    </p>
                </section>

                {/* 7. Modifications des CGU */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">7. Modifications des CGU</h2>
                    <p>
                        <strong>[Nom du client ou de l'entreprise]</strong> se réserve le droit de modifier les présentes CGU à tout moment,
                        notamment pour se conformer à de nouvelles réglementations ou améliorer l'utilisation du site. 
                        Les utilisateurs seront informés de ces modifications via une mise à jour de cette page.
                    </p>
                </section>

                {/* 8. Loi applicable et juridiction compétente */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">8. Loi applicable et juridiction compétente</h2>
                    <p>
                        Les présentes CGU sont régies par la loi française. En cas de litige relatif à l'interprétation ou à l'exécution
                        des présentes CGU, les parties s'engagent à rechercher une solution amiable avant toute action judiciaire. À défaut,
                        le litige sera porté devant les tribunaux compétents de Niort, en France.
                    </p>
                </section>

                {/* 9. Contact */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">9. Contact</h2>
                    <p>
                        Pour toute question relative aux présentes CGU ou pour toute demande d'information supplémentaire,
                        vous pouvez contacter <strong>HYDRO-EVENTS</strong> à l'adresse email suivante :
                        <strong>[email@example.com]</strong>.
                    </p>
                </section>
            </div>
        </div>
    );
}
