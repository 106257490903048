import React from "react";

export default function MentionsLegales() {
    return (
        <div className="bg-gradient-to-b from-black via-[#0A0F2C] to-black pt-36 text-white py-16 px-8">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-bold mb-8 text-yellow-500">Mentions Légales</h1>
                <p className="mb-6">Mis à jour le 05/11/2024</p>
                {/* Section Propriétaire du Site */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">1. Propriétaire du site</h2>
                    <p>
                        Le présent site est la propriété de <strong>HYDRO-EVENT</strong>, 
                        une entreprise spécialisée dans l'animation musicale et l'événementiel, inscrite sous le numéro 
                        de SIRET [numéro de SIRET] et dont le siège social est situé au [adresse complète de l'entreprise].
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">1.1 Éditeur du site</h2>
                    <p>
                        Le présent site est édité par <strong>NoxZi-Dev</strong>, société
                        immatriculée sous le numéro 981 685 662 au Registre du Commerce
                        et des Sociétés de Chambéry, dont le siège social est situé a Chambéry.
                    </p>
                    <p className="mt-2">
                        Responsable de la publication : <strong>NoxZi-Dev</strong><br />
                        Téléphone : <strong>+330675172440</strong><br />
                        Email : <strong>contact@noxzi-dev.fr</strong>
                    </p>
                </section>

                {/* Section Hébergement */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">2. Hébergement du site</h2>
                    <p>
                        Le site est hébergé par <strong>[Nom de l'hébergeur]</strong>, dont le siège social est situé
                        au [adresse complète de l'hébergeur], et qui peut être contacté via le numéro de téléphone suivant :
                        <strong>[numéro de téléphone de l'hébergeur]</strong>.
                    </p>
                </section>

                {/* Section Propriété Intellectuelle */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">3. Propriété intellectuelle</h2>
                    <p>
                        Tous les contenus présents sur ce site (textes, images, vidéos, graphismes, logos, icônes, etc.)
                        sont protégés par les lois françaises et internationales relatives à la propriété intellectuelle.
                        Toute reproduction, distribution, modification, adaptation, retransmission ou publication de ces contenus,
                        sans l'accord exprès écrit de <strong>HYDRO-EVENT</strong>, est strictement interdite.
                    </p>
                </section>

                {/* Section Données personnelles */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">4. Protection des données personnelles</h2>
                    <p>
                        Conformément au Règlement Général sur la Protection des Données (RGPD) et à la loi "Informatique et Libertés",
                        vous disposez d'un droit d'accès, de modification, de rectification et de suppression des données vous concernant.
                        Vous pouvez exercer ce droit en nous contactant à l'adresse email suivante : <strong>[email@example.com]</strong>.
                    </p>
                    <p className="mt-2">
                        Les données personnelles collectées sur ce site sont uniquement utilisées dans le cadre des demandes
                        via le formulaire de contact ou la gestion des devis et ne sont pas transmises à des tiers.
                    </p>
                </section>

                {/* Section Cookies */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">5. Utilisation des cookies</h2>
                    <p>
                        Ce site utilise des cookies afin d'améliorer l'expérience utilisateur et de réaliser des statistiques de visites.
                        Vous pouvez configurer votre navigateur pour refuser les cookies, mais cela pourrait affecter certaines
                        fonctionnalités du site.
                    </p>
                </section>

                {/* Section Responsabilité */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">6. Responsabilité</h2>
                    <p>
                        <strong>HYDRO-EVENT</strong> ne peut être tenue responsable des dommages directs ou indirects
                        causés au matériel de l’utilisateur lors de l’accès au site. Il est recommandé d'utiliser un équipement récent,
                        sans virus et un navigateur de dernière génération.
                    </p>
                </section>

                {/* Section Loi applicable */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">7. Loi applicable</h2>
                    <p>
                        Le présent site est soumis à la loi française. Tout litige relatif à l’utilisation de ce site sera
                        de la compétence exclusive des tribunaux français, et plus particulièrement du tribunal de [ville].
                    </p>
                </section>
            </div>
        </div>
    );
}
