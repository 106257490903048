import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; 
import facebook from "../../images/facebook.png";
import instagram from "../../images/instagram.png";
import youtube from "../../images/youtube.png";
import x from "../../images/twitter.png";
import linkedin from "../../images/linkedin.png";

export default function Footer() {
    const [info, setinfo] = useState('');

    useEffect(() => {
        fetch('/data.json')
            .then(response => response.json())
            .then(data => setinfo(data.general))
            .catch(error => console.error('Erreur lors de la récupération des données :', error));
    }, []);

    return (
        <footer className="bg-black text-white py-10">
            <div className="container mx-auto grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4 px-4 md:px-8">
                {/* Hydro-Event Info Section */}
                <div>
                    <h2 className="text-lg md:text-xl font-bold mb-4">HYDRO-EVENT</h2>
                    <p className="text-sm md:text-base">Audiovisuel professionnel DJ/</p>
                    <p className="text-sm md:text-base">Producteur à mes heures perdues</p>
                    <p className="mt-2 text-sm md:text-base">#ucpa #rkb #srtrpro #flstudio</p>
                    <p className="text-sm md:text-base">#pioneer #cdj</p>
                </div>

                {/* Legal Section */}
                <div>
                    <h2 className="text-lg md:text-xl font-bold mb-4">Légal</h2>
                    <ul className="text-sm md:text-base space-y-2">
                        <li><Link to="/mentions-legales" className="hover:underline">Mentions légales</Link></li>
                        <li><Link to="/cgu" className="hover:underline">Conditions générales d'utilisation (CGU)</Link></li>
                        <li><Link to="/confidentialites" className="hover:underline">Confidentialités</Link></li>
                    </ul>
                    <div className="mt-4">
                        <h2 className="text-lg md:text-xl font-bold mb-4">Contact</h2>
                        <p>Email: <a href={'mailto:' + info.mail} className="hover:underline">{info.mail}</a></p>
                        <p>Téléphone: <a href="tel:+330768607978" className="hover:underline">+33 07 68 60 79 78</a></p>
                    </div>
                </div>

                {/* Shortcuts Section */}
                <div>
                    <h2 className="text-lg md:text-xl font-bold mb-4">Raccourcis</h2>
                    <ul className="text-sm md:text-base space-y-2">
                        <li><Link to="/" className="hover:underline">Accueil</Link></li>
                        <li><Link to="/prestations" className="hover:underline">Nos prestations</Link></li>
                        <li><Link to="/dates" className="hover:underline">Nos dates</Link></li>
                        <li><Link to="/location" className="hover:underline">Location</Link></li>
                        <li><Link to="/galerie" className="hover:underline">Galerie</Link></li>
                        <li><Link to="/contact" className="hover:underline">Contact</Link></li>
                    </ul>
                </div>

                {/* Social Media Section */}
                <div>
                    <h2 className="text-lg md:text-xl font-bold mb-4">Suivez-nous</h2>
                    <p className="text-sm md:text-base">Pour ne rien louper de l'actu ou de nos prochaines prestations, suivez-nous sur les réseaux.</p>
                    <div className="flex flex-wrap justify-start space-x-4 mt-8">
                        <a href={info.facebookUrl} className="hover:scale-110 transition-transform">
                            <img src={facebook} alt="Facebook logo" className="w-6 h-6 sm:w-8 sm:h-8" />
                        </a>
                        <a href={info.instaUrl} className="hover:scale-110 transition-transform">
                            <img src={instagram} alt="Instagram logo" className="w-6 h-6 sm:w-8 sm:h-8" />
                        </a>
                        <a href={info.youtubeUrl} className="hover:scale-110 transition-transform">
                            <img src={youtube} alt="YouTube logo" className="w-6 h-6 sm:w-8 sm:h-8" />
                        </a>
                        <a href={info.xUrl} className="hover:scale-110 transition-transform">
                            <img src={x} alt="X logo" className="w-6 h-6 sm:w-8 sm:h-8" />
                        </a>
                        <a href={info.linkedinUrl} className="hover:scale-110 transition-transform">
                            <img src={linkedin} alt="LinkedIn logo" className="w-6 h-6 sm:w-8 sm:h-8" />
                        </a>
                    </div>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="mt-14 text-center text-gray-500 text-sm px-4">
                <p>©2024 HYDRO-EVENT Tous droits réservés - Développé par <a href="https://noxzi-dev.fr" className="font-bold underline hover:text-white">NoxZi-Dev</a></p>
            </div>
        </footer>
    );
}
