import React from "react";
import { motion } from "framer-motion";
import Img1 from "../../images/profile_noe.png";

const ServiceCardSection = () => {
  const services = [
    {
      title: "Particulier",
      description:
        "DJ généraliste, pour vos soirées privées, mariages, anniversaires et autres célébrations, chaque prestation est conçue sur-mesure pour offrir une ambiance musicale unique. Grâce à une sélection musicale adaptée et un matériel de sonorisation et d'éclairage professionnel, chaque moment devient inoubliable et à l'image des attentes des clients.",
      image: Img1,
    },
    {
      title: "Professionnel",
      description:
        "Proposant des services d'animation pour des événements d'entreprise, séminaires, cocktails ou soirées de gala, chaque prestation DJ est adaptée pour refléter l'identité et les besoins de l'événement. Grâce à une expertise en sonorisation, éclairage et ambiance musicale, chaque événement professionnel devient un moment marquant et parfaitement orchestré.",
      image: Img1,
    },
    {
      title: "Club / Festival",
      description:
        "Offrant des services d'animation pour clubs et festivals, chaque prestation DJ est conçue pour créer une expérience immersive et dynamique. Avec une sélection musicale adaptée aux tendances actuelles, chaque événement devient une célébration inoubliable, captivant le public et favorisant une ambiance électrisante.",
      image: Img1,
    },
  ];

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
  };

  return (
    <div className="py-16 px-6">
      <motion.div
        className="flex flex-col align-justify md:flex-row justify-around items-center gap-12"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: { staggerChildren: 0.3 },
          },
        }}
      >
        {services.map((service, index) => (
          <motion.div
            key={index}
            className="bg-black rounded-lg shadow-lg p-8 w-full md:w-1/3 text-center"
            variants={cardVariants}
          >
            <motion.img
              src={service.image}
              alt={service.title}
              className="w-full rounded-md mb-6"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            />
            <h2 className="text-2xl font-bold text-yellow-500 mb-4">
              {service.title}
            </h2>
            <p className="text-gray-300">{service.description}</p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default ServiceCardSection;
