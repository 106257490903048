import React from 'react';

const CallToActionSection = () => {
  return (
    <div className="text-center py-16 ">
      <button className="bg-yellow-500 text-gray-900 font-bold py-3 px-8 rounded-lg hover:bg-yellow-400 transition duration-300">
        Contactez-Nous pour une prestation
      </button>
    </div>
  );
};

export default CallToActionSection;
