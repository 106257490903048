import React from "react";
import BGHEADERHOMEPAGE from './../images/BG-HEADER-HOMEPAGE.png';
import GallerieSectionPage from "../components/elements/GallerieSectionPage";
import HeaderSection from "../components/layouts/HeaderSection";

  export default function Gallerie() {
    return (
      <div>
            <HeaderSection
                backgroundImage={BGHEADERHOMEPAGE}
                title="Galerie"
                desc=" Nous proposons des services de DJ adaptés à chaque occasion. Que vous organisiez une fête privée,
                un événement d'entreprise ou un festival, nos DJ professionnels sont là pour vous offrir
                une expérience musicale inoubliable."
            />
            <GallerieSectionPage />
      </div>
    );
  }