import React from 'react';
import { motion } from 'framer-motion';

export default function HeaderSection({ backgroundImage, title, desc }) {
  return (
    <header
      className="relative h-screen bg-cover bg-center text-white"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>

      {/* Animated Content */}
      <div className="absolute inset-0 flex flex-col justify-center items-center text-center px-4">
        {/* Title Animation */}
        <motion.h1
          className="text-4xl md:text-6xl lg:text-8xl mb-5 font-custom"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2, ease: "easeOut" }}
        >
          {title}
        </motion.h1>

        {/* Description Animation */}
        <motion.p
          className="mt-8 text-base sm:text-lg md:text-xl lg:text-2xl font-bold max-w-screen-md"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5, ease: "easeOut", delay: 0.3 }}
        >
          {desc}
        </motion.p>
      </div>
    </header>
  );
}
