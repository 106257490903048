import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import backgroundImage from '../../images/date_background.png';

const events = [
  
  {
    year: 2024,
    events: [],
  },
  {
    year: 2022,
    events: [
      {
        month: "JUIL.",
        day: "02",
        location: "CAEN",
        venue: "Portobello Rock Club",
      },
      {
        month: "JUIN",
        day: "29",
        location: "CHERBOURG",
        venue: "Les Art’Zimutés",
      },
    ],
  },
  {
    year: 2021,
    events: [
      {
        month: "AOUT",
        day: "28",
        location: "BARFLEURS",
        venue: "MusiKenSaire",
      },
    ],
  },
];

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeInOut", staggerChildren: 0.3 } },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const buttonVariants = {
  hover: {
    scale: 1.1,
    transition: { duration: 0.3 },
  },
};

export default function EventDatesSection() {
  const controls = useAnimation(); 
  const sectionRef = useRef(null); 
  const [hasAnimated, setHasAnimated] = useState(false); 

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasAnimated) {
            controls.start("visible"); 
            setHasAnimated(true); 
          }
        });
      },
      { threshold: 0.2 } 
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current); 
      }
    };
  }, [controls, hasAnimated]);

  return (
    <section
      className="relative text-white py-12 pb-36"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black z-10"></div>
      <div className="absolute inset-0 bg-gradient-to-b from-[#0A0F2C] to-transparent z-10"></div>

      <motion.div
        ref={sectionRef} 
        className="relative z-20 container mx-auto text-center"
        initial="hidden" 
        animate={controls} 
        variants={containerVariants}
      >
        <motion.h1 className="text-5xl text-center font-custom mb-16" variants={itemVariants}>
          nos dates
        </motion.h1>

        {events.map((eventGroup, index) => (
          <motion.div key={index} className="mb-12" variants={itemVariants}>
            <motion.h3 className="text-3xl font-semibold mb-6" variants={itemVariants}>
              {eventGroup.year}
            </motion.h3>

            {eventGroup.events.length === 0 ? (
              <motion.p className="text-xl" variants={itemVariants}>
                AUCUNE DATES À VENIR
              </motion.p>
            ) : (
              eventGroup.events.map((event, idx) => (
                <motion.div
                  key={idx}
                  className="flex justify-between items-center bg-[#141418] rounded-lg p-4 mb-4"
                  variants={itemVariants}
                >
                  <div className="flex items-center">
                    <div className="text-center text-yellow-500 mr-4">
                      <p className="text-lg font-bold uppercase">{event.month}</p>
                      <p className="text-4xl font-bold">{event.day}</p>
                    </div>
                    <div className="text-left">
                      <p className="text-xl font-semibold uppercase">{event.location}</p>
                      <p className="text-gray-400">{event.venue}</p>
                    </div>
                  </div>

                  <motion.button
                    className="bg-pink-500 text-white px-6 py-2 rounded-full hover:bg-pink-400 transition duration-300"
                    whileHover="hover"
                    variants={buttonVariants}
                  >
                    INFOS
                  </motion.button>
                </motion.div>
              ))
            )}
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
}
