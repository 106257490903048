import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accepter"
      declineButtonText="Refuser"
      enableDeclineButton
      style={{ background: "#000" }}
      buttonStyle={{ color: "#000", fontSize: "13px", backgroundColor: "#FFD700", borderRadius: "6px"}}
      declineButtonStyle={{ color: "#fff", backgroundColor: "#E0115F", fontSize: "13px", borderRadius: "6px" }}
      expires={150}
    >
      Ce site utilise des cookies pour améliorer votre expérience.{" "}
      <Link to="/confidentialites" className="text-[#FFD700] underline">
        En savoir plus.
      </Link>
    </CookieConsent>
  );
};

export default CookieBanner;
