import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home';
import PrestationsPage from './pages/Prestations';
import Footer from './components/layouts/Footer';
import NavBar from './components/layouts/NavBar';
import EventPage from './pages/Event';
import Gallerie from './pages/Galerie';
import Locations from './pages/Location';
import ContactPage from './pages/Contact';

import CookieBanner from './components/layouts/CookieBanner';
import Confidentialités from './components/legal/confidentialite';
import MentionsLegales from './components/legal/MentionsLegales';
import CGU from './components/legal/CGU';

function App() {
  return (
    <Router>
      <NavBar />
      <CookieBanner />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/prestations" element={<PrestationsPage />} />
        <Route path='/dates' element={<EventPage />} />
        <Route path='/location' element={<Locations />} />
        <Route path='/galerie' element={<Gallerie />} />
        <Route path='/contact' element={<ContactPage />} />

        <Route path='/confidentialites' element={<Confidentialités />} />
        <Route path='/mentions-legales' element={<MentionsLegales />} />
        <Route path='/cgu' element={<CGU />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
