import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function EquipmentSection() {
  const [equipmentList, setEquipmentListData] = useState([]);

  useEffect(() => {
    fetch("/data.json")
      .then((response) => response.json())
      .then((data) => setEquipmentListData(data.MaterielLocationPage))
      .catch((error) =>
        console.error("Erreur lors de la récupération des données :", error)
      );
  }, []);

  // Variants for cards animation
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
  };

  return (
    <section className="py-12 bg-gradient-to-b from-black to-[#0A0F2C]">
      <div className="container mx-auto max-w-screen-xl text-left">
        <h2 className="text-4xl font-semibold text-white mb-12">
          Matériel à la Location
        </h2>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-3 gap-8 px-4"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: { staggerChildren: 0.3 },
            },
          }}
        >
          {equipmentList.map((equipment, index) => (
            <motion.div
              key={index}
              className="bg-[#141418] rounded-lg flex flex-col items-left text-left max-w-xl mx-auto"
              whileHover={{ scale: 1.05 }}
              variants={cardVariants}
            >
              <img
                src={equipment.image}
                alt={equipment.name}
                className="w-full h-88 object-contain mb-4"
              />

              <div className="px-6">
                <h3 className="text-xl font-bold text-white mb-2">
                  {equipment.name}
                </h3>
                <p className="text-gray-400 text-sm mb-4">
                  {equipment.description}
                </p>

                <h4 className="mb-6">À partir de {equipment.price}€</h4>
                <button className="bg-yellow-500 mb-8 text-black font-bold py-2 px-4 rounded-lg hover:bg-yellow-600 transition duration-300">
                  <Link to="/contact">Demande de location</Link>
                </button>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
}
