import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import imageDJ from '../../images/imageDJ-platines-section1.png';
import ParticulierImage from '../../images/imageDJ-platines-section1.png';
import ProfessionnelImage from '../../images/imageDJ-platines-section1.png';
import ClubFestivalImage from '../../images/imageDJ-platines-section1.png';

export default function Prestations() {
  const controls = useAnimation();
  const sectionRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasAnimated) {
            controls.start("visible");
            setHasAnimated(true);
          }
        });
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [controls, hasAnimated]);

  const prestations = [
    {
      title: 'Prestation particulier',
      image: ParticulierImage,
      buttonText: 'Demander un devis',
      buttonColor: 'bg-pink-500 hover:bg-pink-400',
    },
    {
      title: 'Professionnel',
      image: ProfessionnelImage,
      buttonText: 'Demander un devis',
      buttonColor: 'bg-yellow-500 hover:bg-yellow-400',
    },
    {
      title: 'Club/Festival',
      image: ClubFestivalImage,
      buttonText: 'Demander un devis',
      buttonColor: 'bg-pink-500 hover:bg-pink-400',
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeInOut", staggerChildren: 0.3 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <motion.section
      className="bg-black text-white py-12 px-6"
      ref={sectionRef}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <h1 className="text-5xl text-center font-custom mb-16">Nos Prestations</h1>
      
      <div className="container mx-auto mt-14 flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0 md:space-x-14">
        <motion.div className="md:w-1/2 w-full" variants={itemVariants}>
          <img 
            src={imageDJ} 
            alt="DJ Setup" 
            className="rounded-lg w-full h-auto"
          />
        </motion.div>

        <motion.div className="md:w-1/2 w-full" variants={itemVariants}>
          <div className="mb-6">
            <h3 className="text-2xl font-semibold">Particulier</h3>
            <p className="text-gray-300 mt-2 font-rubik">
              DJ généraliste, pour vos soirées privés, mariages, anniversaires et autres célébrations, chaque prestation est conçue sur-mesure pour offrir une ambiance musicale unique.
              Grâce à une sélection musicale adaptée et un matériel de sonorisation et d'éclairage professionnel, chaque moment devient inoubliable et à l'image des attentes des clients.
            </p>
          </div>

          <div className="mb-6">
            <h3 className="text-2xl font-semibold">Professionnel</h3>
            <p className="text-gray-300 mt-2 font-rubik">
              Proposant des services d'animation pour des événements d'entreprise, séminaires, coktails ou soirées de gala, chaque prestation DJ est adaptée pour refléter l'identité et les besoins de l'événement. 
              Grâce à une expertise en sonorisation, éclairage et ambiance musicale, chaque événement professionnel devient un moment marquant et parfaitement orchestré.
            </p>
          </div>

          <div className="mb-6">
            <h3 className="text-2xl font-semibold">Club/Festival</h3>
            <p className="text-gray-300 mt-2 font-rubik">
              Offrant des services d'animation pour clubs et festivals, chaque prestation DJ est conçue pour créer une experience immersive et dynamique. 
              Avec une sélection musicale adaptée aux tendances actuelles, chaque événement devient une célébration inoubliable, captivant le public et favorisant une ambiance électrisante.
            </p>
          </div>

          <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4 mt-8">
            <a 
              href="#decouvrir-prestations"
              className="bg-[#FFD700] text-black px-6 py-2 rounded-full hover:bg-yellow-400 transition duration-300 w-full md:w-auto text-center"
            >
              Découvrir nos prestations
            </a>
            <a
              href="#demander-devis"
              className="bg-[#E0115F] text-white px-6 py-2 rounded-full hover:bg-red-400 transition duration-300 w-full md:w-auto text-center"
            >
              Demander un devis
            </a>
          </div>
        </motion.div>
      </div>

      <motion.section className="py-12 px-6 bg-black text-white" variants={containerVariants}>
  <h2 className="text-center text-4xl font-bold mb-8">Nos Prestations</h2>
  <div className="flex flex-wrap justify-center">
    {prestations.map((prestation, index) => (
      <motion.div key={index} className="relative group w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2" variants={itemVariants}>
        <img
          src={prestation.image}
          alt={prestation.title}
          className="w-full h-auto object-cover rounded-md transition-transform duration-300 transform group-hover:scale-105"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 border-4 flex flex-col justify-end p-4 md:p-8 m-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <h3 className="text-xl font-semibold">{prestation.title}</h3>
          <button
            className={`${prestation.buttonColor} text-white mt-4 px-4 py-2 rounded-full font-semibold transition-colors duration-300`}
          >
            {prestation.buttonText}
          </button>
        </div>
      </motion.div>
    ))}
  </div>
</motion.section>

    </motion.section>
  );
}
