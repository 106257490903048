import React from "react";
import Header from '../components/layouts/Header';
import Prestations from '../components/elements/Prestations';
import LocationSection from '../components/elements/LocationSection';
import GallerySection from '../components/elements/GallerySection';
import FaqSection from '../components/elements/FaqSection';
import FonctionnementSection from '../components/elements/FonctionnementSection ';
import EventDatesSection from '../components/elements/EventDatesSection';
import ContactForm from '../components/elements/ContactForm';
import BGHEADERHOMEPAGE from '../images/BG-HEADER-HOMEPAGE.png'
import CalendlyIframe from "../components/iframe/Calendly";

export default function HomePage() {
    return(
        <div className="bg-black">
            <Header backgroundImage={BGHEADERHOMEPAGE} title={"HYDRO-EVENT"} desc="Hydro-Event : Là où chaque événement commence"/>
            <Prestations />
            <LocationSection />
            <GallerySection />
            <FaqSection />
            <FonctionnementSection />
            
            <EventDatesSection />
            <ContactForm />
        </div>
    )
}