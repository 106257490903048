import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import profileNoe from '../../images/profile_noe.png';

export default function FaqSection() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    fetch('/data.json')
      .then(response => response.json())
      .then(data => setFaqData(data.faqData))
      .catch(error => console.error('Erreur lors de la récupération des données :', error));
  }, []);

  const toggleAnswer = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqItemVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3, ease: "easeOut" } }
  };

  return (
    <section className="bg-black text-white py-12 px-6">
      <motion.div
        className="container mx-auto flex flex-col md:flex-row items-start justify-between"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
        }}
      >
        {/* Left Column */}
        <motion.div
          className="w-full md:w-1/2"
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0, transition: { duration: 0.6, ease: "easeOut" } }
          }}
        >
          <h1 className="text-5xl font-custom mb-8 text-center md:text-left">
            FOIRES AUX QUESTIONS
          </h1>
          <div className="space-y-6">
            {faqData.map((item, index) => (
              <motion.div
                key={index}
                className="border-b border-gray-500 pb-4"
                variants={faqItemVariant}
              >
                <h2
                  className="text-xl font-semibold cursor-pointer"
                  onClick={() => toggleAnswer(index)}
                >
                  {item.question}
                </h2>
                {activeIndex === index && (
                  <motion.p
                    className="text-gray-400 font-rubik mt-4"
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {item.answer}
                  </motion.p>
                )}
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Right Column */}
        <motion.div
          className="w-full md:w-1/2 mt-8 md:mt-0 flex justify-center"
          variants={{
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0, transition: { duration: 0.6, ease: "easeOut" } }
          }}
        >
          <img
            src={profileNoe}
            alt="DJ Setup"
            className="rounded-md"
          />
        </motion.div>
      </motion.div>
    </section>
  );
}
