import React from "react";
import HeaderSection from '../components/layouts/HeaderSection';
import UpcomingEventsSection from "../components/elements/UpcomingEventsSection";
import PastEventsSection from "../components/elements/PastEventsSection";
import BGHEADERHOMEPAGE from './../images/date_background.png';

const upcomingEvents = [
  {
    month: "OCT",
    day: "14",
    location: "PARIS",
    venue: "La Bellevilloise",
  },
  {
    month: "NOV",
    day: "07",
    location: "LYON",
    venue: "Le Sucre",
  },
];

const pastEvents = [
  {
    month: "AOUT",
    day: "28",
    location: "BARFLEURS",
    venue: "MusiKenSaire",
  },
  {
    month: "JUIN",
    day: "29",
    location: "CHERBOURG",
    venue: "Les Art’Zimutés",
  },
];

export default function EventPage() {
  return (
    <div className="bg-black text-white">
      {/* Header */}
      <HeaderSection
        backgroundImage={BGHEADERHOMEPAGE}
        title="Nos Dates"
        desc="Découvrez nos prochaines prestations publiques en clubs, festivals ou autres événements. Consultez également nos dates passées pour voir où nous avons déjà joué."
      />

      <div className="bg-gradient-to-b from-black via-[#0A0F2C] to-black">
        <UpcomingEventsSection events={upcomingEvents} />

        <PastEventsSection events={pastEvents} />
      </div>
    </div>
  );
}
