import React from 'react';
import HeaderSection from '../components/layouts/HeaderSection';
import ServiceCardSection from '../components/elements/ServiceCardSection';
import CallToActionSection from '../components/elements/CallToActionSection';
import BGHEADERHOMEPAGE from './../images/imageDJ-platines-section1.png'

export default function PrestationsPage() {
  return (
    <div className="bg-black text-white">
       <HeaderSection
        backgroundImage={BGHEADERHOMEPAGE}
        title="Nos Prestations"
        desc="Nous proposons des services de DJ adaptés à chaque occasion. Que vous organisiez une fête privée,
        un événement d'entreprise ou un festival, nos DJ professionnels sont là pour vous offrir
        une expérience musicale inoubliable."
      />
      <div className='bg-gradient-to-b from-black via-[#0A0F2C] to-black'>
        <ServiceCardSection />
        <CallToActionSection />
      </div>
    </div>
  );
}

