import React, { useState, useEffect} from 'react';
import { PopupButton } from 'react-calendly';

const CalendlyPopupButton = ({ btnText, color, colorHover, text }) => {
    const [urlCalendly, setUrlCalendly] = useState('');

    useEffect(() => {
      fetch('/data.json')  // Appel de ton fichier JSON
        .then(response => response.json())  // Conversion en JSON
        .then(data => setUrlCalendly(data.general))  // Récupérer le texte et mettre à jour l'état
        .catch(error => console.error('Erreur lors de la récupération des données :', error));
    }, []);
  return (
      <PopupButton
        url={urlCalendly.urlCalendly}
        rootElement={document.getElementById('root')}
        text={btnText}
        className={`bg-[${color}] text-black font-semibold items-center px-6 py-2 rounded-full hover:bg-[${colorHover}] transition duration-300`}
      />
  );
};

export default CalendlyPopupButton;
