import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imageDJ from '../../images/imageDJ-platines-section1.png';

export default function GallerySection() {
  const [images, setimagesData] = useState([]); 
    
  useEffect(() => {
      fetch('/data.json')
          .then(response => response.json())
          .then(data => setimagesData(data.gallerySectionPhoto)) 
          .catch(error => console.error('Erreur lors de la récupération des données :', error));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };


  const ImageItem = ({ image, title }) => {
    const controls = useAnimation();
    const ref = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              controls.start("visible");
            }
          });
        },
        { threshold: 0.2 } // Animation lorsque 20% de l'image est visible
      );

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [controls]);

    const itemVariants = {
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1, transition: { duration: 0.6 } },
    };

    return (
      <motion.div
        className="relative group p-4"
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={itemVariants}
      >
        <img
          src={image}
          alt={title}
          className="w-[445px] h-100 object-cover rounded-md transition-transform duration-300 transform group-hover:scale-105"
        />
        <div className="absolute inset-0 border-4 flex flex-col p-8 m-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">

        </div>
      </motion.div>
    );
  };

  return (
    <section className="py-12 px-6 bg-black text-white">
      <h1 className="text-5xl text-center font-custom">GALERIE PHOTO</h1>
      <p className="text-center mt-8">
        Voici en images quelques prestations éléctrisantes d'Hydro.
      </p>

      <div className="container mx-auto mt-6 mb-16">
        <Slider {...settings}>
          {images.map((image, index) => (
            <ImageItem key={index} image={image.src} title={image.alt} />
          ))}
        </Slider>
        <div className="flex justify-center mt-10">
          <a
            href="#"
            className="bg-[#E0115F] items-center text-white px-6 py-2 rounded-full hover:bg-red-400 transition duration-300"
          >
            Découvrir notre gallerie
          </a>
        </div>
      </div>
    </section>
  );
}
