import React from "react";
import { motion } from "framer-motion";

export default function CallToActionLocation() {
  return (
    <motion.section
      className="py-12 bg-gradient-to-b from-[#0A0F2C] to-black text-center"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
      }}
    >
      <div className="container mx-auto">
        <motion.h2
          className="text-4xl font-semibold mb-6 text-white"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
          }}
        >
          Besoin de matériel pour votre prochain événement ?
        </motion.h2>
        <motion.p
          className="text-gray-400 mb-8"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.2 } },
          }}
        >
          Contactez-nous dès maintenant pour obtenir des informations détaillées sur nos offres de location de matériel DJ et événementiel.
        </motion.p>
        <motion.button
          className="bg-pink-500 text-white px-8 py-3 rounded-full hover:bg-pink-400 transition duration-300"
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1, transition: { duration: 0.5, delay: 0.4 } },
          }}
        >
          Nous Contacter
        </motion.button>
      </div>
    </motion.section>
  );
}
