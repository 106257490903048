import React from "react";
import { motion } from "framer-motion";

export default function Header({ backgroundImage, title, desc }) {
  return (
    <header
      className="relative h-screen bg-cover bg-center text-white"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>

      {/* Animated Content */}
      <div className="absolute inset-0 flex flex-col justify-center items-center text-center">
        {/* Title Animation */}
        <motion.h1
          className="text-6xl mb-5 font-custom md:text-8xl"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          {title}
        </motion.h1>

        {/* Description Animation */}
        <motion.p
          className="mt-8 text-2xl font-bold"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5, ease: "easeOut", delay: 0.3 }}
        >
          {desc}
        </motion.p>
      </div>
    </header>
  );
}
