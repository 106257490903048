import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";
import backgroundImage from '../../images/BG_Patern.png';

export default function ContactForm() {
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const form = useRef();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_at61mni',
      'template_pt8kcis',
      form.current,
      'YbGXUnW8SGN27r_9a'
    )
      .then((result) => {
        console.log("Email envoyé :", result.text);
        console.log("formData :", formData);
        setIsSubmitted(true);
        alert("Votre message a été envoyé avec succès !");
        setFormData({ nom: "", prenom: "", email: "", message: "" });
      }, (error) => {
        console.error("Erreur lors de l'envoi de l'email :", error.text);
        alert("Une erreur est survenue, veuillez réessayer.");
      });
  };

  const sectionVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } }
  };

  return (
    <section
      className="relative flex justify-center items-center py-16 px-6 bg-black text-white"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black opacity-75 z-10"></div>
      <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-transparent opacity-75 z-10"></div>

      <motion.div
        className="relative z-10 w-full max-w-4xl"
        variants={sectionVariant}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }} // Animate only when 20% is visible
      >
        <motion.h1
          className="text-5xl text-center font-custom mb-8"
          variants={{
            hidden: { opacity: 0, y: -30 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          CONTACTEZ-NOUS
        </motion.h1>

        {isSubmitted ? (
          <motion.p
            className="text-center text-xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            viewport={{ once: true }}
          >
            Merci pour votre message ! Nous vous répondrons dès que possible.
          </motion.p>
        ) : (
          <motion.form
            ref={form}
            className="space-y-6"
            onSubmit={handleSubmit}
            initial="hidden"
            whileInView="visible"
            variants={{
              hidden: { opacity: 0, scale: 0.9 },
              visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
            }}
            viewport={{ once: true }}
          >
            <div className="flex flex-col md:flex-row md:space-x-4">
              <motion.div
                className="w-full"
                variants={sectionVariant}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
              >
                <label htmlFor="nom" className="block mb-2">Nom</label>
                <input
                  id="nom"
                  name="nom"
                  type="text"
                  value={formData.nom}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-[#1A1A1A] border-none rounded focus:ring-2 focus:ring-yellow-400"
                />
              </motion.div>

              <motion.div
                className="w-full mt-4 md:mt-0"
                variants={sectionVariant}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
              >
                <label htmlFor="prenom" className="block mb-2">Prénom</label>
                <input
                  id="prenom"
                  name="prenom"
                  type="text"
                  value={formData.prenom}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-[#1A1A1A] border-none rounded focus:ring-2 focus:ring-yellow-400"
                />
              </motion.div>
            </div>

            <div className="flex flex-col md:flex-row md:space-x-4">
              <motion.div
                className="w-full"
                variants={sectionVariant}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
              >
                <label htmlFor="email" className="block mb-2">Courriel</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-[#1A1A1A] border-none rounded focus:ring-2 focus:ring-yellow-400"
                />
              </motion.div>

              <motion.div
                className="w-full mt-4 md:mt-0"
                variants={sectionVariant}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
              >
                <label htmlFor="phone" className="block mb-2">Téléphone</label>
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-[#1A1A1A] border-none rounded focus:ring-2 focus:ring-yellow-400"
                />
              </motion.div>
            </div>

            <motion.div
              variants={sectionVariant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <label htmlFor="message" className="block mb-2">Votre message</label>
              <textarea
                id="message"
                name="message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-4 py-2 bg-[#1A1A1A] border-none rounded focus:ring-2 focus:ring-yellow-400"
              ></textarea>
            </motion.div>

            <div className="text-center">
              <motion.button
                type="submit"
                className="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-2 px-6 rounded-full transition duration-300"
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.4 }}
                viewport={{ once: true }}
              >
                Valider le formulaire
              </motion.button>
            </div>
          </motion.form>
        )}
      </motion.div>
    </section>
  );
}
