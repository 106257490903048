import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import HeaderSection from "../components/layouts/HeaderSection";
import BGHEADERHOMEPAGE from './../images/profile_noe.png';
import ContactForm from "../components/elements/ContactForm";
import CalendlyIframe from "../components/iframe/Calendly";

export default function ContactPage() {
  const [urlCalendly, setUrlCalendly] = useState('');

  useEffect(() => {
    fetch('/data.json')
      .then((response) => response.json())
      .then((data) => setUrlCalendly(data.general))
      .catch((error) => console.error('Erreur lors de la récupération des données :', error));
  }, []);

  return (
    <div>
      {/* Header Section */}
      <HeaderSection
        backgroundImage={BGHEADERHOMEPAGE}
        title="Contact"
        desc="Contactez-nous pour toutes demandes de devis ou pour tous renseignements."
      />

      {/* Description Section */}
      <div className="bg-black text-white text-center py-8 px-4 sm:px-8 md:px-16 lg:px-24 xl:px-48">
        <motion.p
          className="text-sm sm:text-base md:text-lg lg:text-xl"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          Pour toute demande de location ou de devis, veuillez utiliser ce formulaire (ou nous écrire par mail)
          ou nous téléphoner, pour discuter de tout cela ou pour toute question concernant nos services.
        </motion.p>
      </div>


    <ContactForm />
  

      {/* Calendly Section */}
      <div className="bg-black text-white flex flex-col items-center py-16">
        <motion.h2
          className="text-2xl font-bold mb-4"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          Prendre Rendez-vous
        </motion.h2>

        <motion.p
          className="mb-8 text-center px-8"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          Cliquez ci-dessous pour planifier un rendez-vous avec Noe via Calendly, ou utilisez le formulaire ci-dessus pour toute autre demande.
        </motion.p>

        <CalendlyIframe btnText="Ouvrir Calendly" color="#FFD700" colorHover="#FEA160" text="#000" />

        <motion.div
          className="mt-12 w-full max-w-3xl"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <iframe
            src={urlCalendly.urlCalendly}
            width="100%"
            height="600px"
            frameBorder="0"
            className="rounded-lg shadow-lg"
            allowFullScreen
          ></iframe>
        </motion.div>
      </div>

      {/* Profile Section */}
      <div className="flex items-center justify-center bg-black py-16">
        <motion.div
          className="flex items-center space-x-8 p-8 rounded-lg shadow-lg max-w-xl "
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <div className="w-46 h-56 rounded-lg overflow-hidden">
            <img
              src={BGHEADERHOMEPAGE}
              alt="Noe Bolzer"
              className="w-full h-full object-cover"
            />
          </div>

          <div>
            <h2 className="text-2xl text-white font-bold">Noe Bolzer</h2>
            <h2 className="text-xl text-white font-bold">Dirigeant & DJ</h2>
            <p className="text-[#ffffff9e] mt-4">
              <a href="tel:+33 07 68 60 79 78" className="hover:text-white transition-colors duration-300">
                +33 07 68 60 79 78
              </a>
            </p>
            <p className="text-[#ffffff9e]">
              <a href="mailto:hydro.event79@gmail.com" className="hover:text-white transition-colors duration-300">
                hydro.event79@gmail.com
              </a>
            </p>
            <p className="text-[#ffffff9e]">149 Cours de la Marne, 33800 Bordeaux</p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
