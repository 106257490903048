import React, { useState, useEffect } from "react";

export default function Confidentialites() {

    const [info, setinfo] = useState('');

    useEffect(() => {
        fetch('/data.json')
            .then(response => response.json())
            .then(data => setinfo(data.general))
            .catch(error => console.error('Erreur lors de la récupération des données :', error));
    }, []);
    
    return (
        <div className="bg-gradient-to-b from-black via-[#0A0F2C] to-black pt-36 text-white py-16 px-8">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-bold mb-8 text-yellow-500">
                    Politiques de Confidentialité et de Cookies
                </h1>
                <p className="mb-6">Mis à jour le 24/11/2024</p>

                <div className="space-y-6">
                    <section>
                        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
                        <p>
                            Nous respectons votre vie privée et nous engageons à protéger
                            les informations personnelles que vous partagez avec nous
                            via notre formulaire de contact.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold mb-4">
                            2. Informations collectées
                        </h2>
                        <p>
                            Lorsque vous remplissez notre formulaire de contact, nous
                            collectons les informations suivantes :
                        </p>
                        <ul className="list-disc list-inside ml-4">
                            <li>Votre nom</li>
                            <li>Votre prénom</li>
                            <li>Votre adresse email</li>
                            <li>Votre téléphone</li>
                            <li>Votre message ou demande</li>
                        </ul>
                        <p className="mt-4">
                            Ces informations sont utilisées uniquement pour répondre à vos
                            demandes et ne sont jamais partagées avec des tiers.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold mb-4">
                            3. Utilisation des cookies
                        </h2>
                        <p>
                            Nous utilisons uniquement des cookies essentiels pour
                            garantir le bon fonctionnement du site. Ces cookies ne
                            collectent aucune donnée personnelle.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold mb-4">
                            4. Vos droits
                        </h2>
                        <p>
                            Vous avez le droit de demander l'accès, la modification ou la
                            suppression de vos données personnelles en nous contactant
                            via l'adresse email fournie sur le site.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold mb-4">
                            5. Contact
                        </h2>
                        <p>
                            Pour toute question concernant notre politique de
                            confidentialité, vous pouvez nous écrire à :
                        </p>
                        <p className="text-yellow-500 mt-2">{info.mail}</p>
                    </section>
                </div>
            </div>
        </div>
    );
}
