import React from "react";
import HeaderSection from '../components/layouts/HeaderSection';
import BGHEADERHOMEPAGE from './../images/BG-HEADER-HOMEPAGE.png'
import EquipmentSection from "../components/elements/EquipmentSection";
import CallToActionLocation from "../components/elements/CallToActionLocation";

export default function Locations() {
    return(
        <div className="bg-black text-white">
            <HeaderSection
                backgroundImage={BGHEADERHOMEPAGE}
                title="Nos Locations"
                desc=" Nous proposons des services de DJ adaptés à chaque occasion. Que vous organisiez une fête privée,
                un événement d'entreprise ou un festival, nos DJ professionnels sont là pour vous offrir
                une expérience musicale inoubliable."
            />

            <EquipmentSection />

            <CallToActionLocation />
        </div>
    )
}