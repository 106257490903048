import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imgTest from '../../images/locationtest.png';
import { Link } from "react-router-dom";

export default function LocationSection() {
  const controls = useAnimation();
  const sectionRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [products, setiproductsData] = useState([]); 
    
  useEffect(() => {
      fetch('/data.json')
          .then(response => response.json())
          .then(data => setiproductsData(data.MaterielLocationHomePage)) 
          .catch(error => console.error('Erreur lors de la récupération des données :', error));
  }, []);
  

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasAnimated) {
            controls.start("visible");
            setHasAnimated(true);
          }
        });
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [controls, hasAnimated]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeInOut", staggerChildren: 0.3 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <section className="bg-gradient-to-b from-black via-[#0A0F2C] to-black text-white py-12 px-6">
      <h1 className="text-5xl text-center font-custom">Nos locations</h1>
      <p className="text-center mt-8">
        Vous retrouverez dans cette section, du matériel amateur comme professionnel disponible à la location.
      </p>

      <motion.div
        className="container mx-auto mt-6 mb-16"
        variants={containerVariants}
      >
        <Slider {...settings}>
          {products.map((product) => (
            <motion.div
              key={product.id}
              className="p-4"
              variants={itemVariants}
            >
              <div className="bg-black rounded-lg shadow-lg shadow-[#0A0F2C]">
                <img
                  src={product.image}
                  alt={product.description}
                  className="w-full h-60 md:h-72 lg:h-80 object-cover rounded-t-lg" 
                />
                <div className="p-4">
                  <h3 className="text-lg font-bold mb-2">{product.label}</h3>
                  <p className="text-gray-300">{product.description}</p>
                  <button className="mt-4 bg-yellow-400 text-black font-bold py-2 px-4 rounded hover:bg-yellow-500">
                    <Link to="/contact">Demande de location</Link>
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </Slider>
      </motion.div>
    </section>
  );
}
