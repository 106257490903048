import React, { useState } from "react";
import { Link } from "react-router-dom"; 
import logo from '../../images/LOGO.png';

export default function NavBar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <nav className="absolute top-0 left-0 px-5 py-6 w-full bg-transparent text-white z-10">
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <img src={logo} alt="HYDRO Logo" className="h-18 w-auto" />
                </div>
                <div className="block lg:hidden">
                    <button onClick={toggleMenu} className="focus:outline-none">
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                </div>
                {/* Menu Desktop */}
                <ul className={`hidden lg:flex lg:space-x-6`}>
                    <li>
                        <Link to="/" className="block py-2 hover:text-yellow-500">Accueil</Link>
                    </li>
                    <li>
                        <Link to="/prestations" className="block py-2 hover:text-yellow-500">Nos prestations</Link>
                    </li>
                    <li>
                        <Link to="/dates" className="block py-2 hover:text-yellow-500">Nos dates</Link>
                    </li>
                    <li>
                        <Link to="/location" className="block py-2 hover:text-yellow-500">Location</Link>
                    </li>
                    <li>
                        <Link to="/galerie" className="block py-2 hover:text-yellow-500">Galerie</Link>
                    </li>
                    <li>
                        <Link to="/contact" className="bg-yellow-500 text-white px-6 py-2 rounded-full hover:bg-yellow-400 block text-center">
                            Contact
                        </Link>
                    </li>
                </ul>
            </div>

            {isOpen && (
                <div 
                    className="fixed inset-0 bg-black opacity-50 z-0" 
                    onClick={closeMenu}
                />
            )}

            {isOpen && (
                <div className="absolute top-0 left-0 w-full bg-black z-10">
                    <ul className="flex flex-col items-center py-4 align-right">
                        <li>
                            <Link to="/" className="block py-2 px-4 text-white hover:text-yellow-500" onClick={closeMenu}>Accueil</Link>
                        </li>
                        <li>
                            <Link to="/prestations" className="block py-2 px-4 text-white hover:text-yellow-500" onClick={closeMenu}>Nos prestations</Link>
                        </li>
                        <li>
                            <Link to="/dates" className="block py-2 px-4 text-white hover:text-yellow-500" onClick={closeMenu}>Nos dates</Link>
                        </li>
                        <li>
                            <Link to="/location" className="block py-2 px-4 text-white hover:text-yellow-500" onClick={closeMenu}>Location</Link>
                        </li>
                        <li>
                            <Link to="/galerie" className="block py-2 px-4 text-white hover:text-yellow-500" onClick={closeMenu}>Galerie</Link>
                        </li>
                        <li>
                            <Link to="/contact" className="bg-yellow-500 text-white px-6 py-2 rounded-full hover:bg-yellow-400 block text-center" onClick={closeMenu}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
}
