import React from "react";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeInOut", staggerChildren: 0.3 } },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

export default function PastEventsSection({ events }) {
  return (
    <section className=" py-12">
      <motion.div
        className="container mx-auto text-center"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.h2 className="text-4xl font-semibold mb-8" variants={itemVariants}>
          Dates Passées
        </motion.h2>

        {events.length === 0 ? (
          <motion.p className="text-xl" variants={itemVariants}>
            Aucune date passée à afficher.
          </motion.p>
        ) : (
          events.map((event, index) => (
            <motion.div
              key={index}
              className="flex justify-between items-center bg-[#141418] rounded-lg p-4 mb-4"
              variants={itemVariants}
            >
              <div className="flex items-center">
                <div className="text-center text-yellow-500 mr-4">
                  <p className="text-lg font-bold uppercase">{event.month}</p>
                  <p className="text-4xl font-bold">{event.day}</p>
                </div>
                <div className="text-left">
                  <p className="text-xl font-semibold uppercase">{event.location}</p>
                  <p className="text-gray-400">{event.venue}</p>
                </div>
              </div>
            </motion.div>
          ))
        )}
      </motion.div>
    </section>
  );
}
